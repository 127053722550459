import React from "react";

import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";

export default function Quote(props) {
  return (
    <div className="w-full mb-10">
      <header className="w-full">
        <LazyLoadImage
          className="m-auto"
          height="35"
          width="44"
          style={{
            height: `35px`,
            lineHeight: `44px`,
          }}
          onError={(e) => {
            e.target.classList.add("error");
          }}
          src={`/images/icon-quote.svg`}
          alt={props.children}
        />
      </header>
      <div
        className={`flex items-center ${
          props.layout === "right" && "flex-row-reverse"
        }`}
        style={{}}
      >
        <LazyLoadComponent>
          <picture
            className={`rounded-full flex-none  hidden md:block ${
              props.layout === "right"
                ? "ml-6 lg:ml-10 xl:ml-14"
                : "mr-6 lg:mr-10 xl:mr-14"
            }`}
            style={{
              border: "solid 6px white",
              boxShadow: `#D61F1F 0px 0px 0px 1px`,
            }}
          >
            <source
              srcSet={`/images/photo-${props.photo}.webp, /images/photo-${props.photo}@2x.webp 2x`}
              type="image/webp"
            />
            <source
              srcSet={`/images/photo-${props.photo}.jpg, /images/photo-${props.photo}@2x.jpg 2x`}
              type="image/jpeg"
            />
            <img
              className="rounded-full relative z-20 w-40 md:w-52 lg:w-56 xl:w-64"
              src={`/images/photo-${props.photo}.jpg`}
              alt={`${props.name}, ${props.title}`}
            />
          </picture>
        </LazyLoadComponent>
        <figure className="my-12">
          <blockquote className="text-xl md:text-2xl lg:text-3xl font-light text-denim antialiased mb-5">
            {props.children}
          </blockquote>
          <figcaption className="text-lg md:text-xl lg:text-2xl font-medium tracking-wide">
            &mdash; {props.name}, {props.title}
          </figcaption>
        </figure>
      </div>
    </div>
  );
}
