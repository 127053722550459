import React from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SectionLabel(props) {
  return (
    <h3
      className={`label relative z-10 text-rose ${
        props.color && "label-" + props.color
      }`}
    >
      <span>{props.children}</span>
      <LazyLoadImage
        height={props.height}
        width={props.width}
        style={{
          height: `${props.height}px`,
          lineHeight: `${props.height}px`,
        }}
        onError={(e) => {
          e.target.classList.add("error");
        }}
        src={`/images/label-${props.name}.svg`}
        alt={props.children}
      />
    </h3>
  );
}
