import React from "react";

export default function SectionTitle(props) {
  return (
    <h4
      className={`title text-${
        props.color
      } text-2xl md:text-3xl px-6 text-center text-ruby ${
        !!props.variation && "title_" + props.variation
      }`}
    >
      {props.children}
    </h4>
  );
}
