import React, { useRef, useEffect, useState } from "react";
import useInView from "react-cool-inview";
import { Helmet } from "react-helmet";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import { Element, scroller } from "react-scroll";

import CodeWhite from "../../static/images/code-white.inline.svg";
import CodeRed from "../../static/images/code-red.inline.svg";
import Title from "../../static/images/title.inline.svg";
import Subtitle from "../../static/images/subtitle.inline.svg";
import HighlightCompanies from "../../static/images/highlight-companies.inline.svg";
import HighlightCreativity from "../../static/images/highlight-creativity.inline.svg";
import IconCompanies from "../../static/images/icon-target-companies.inline.svg";
import IconDevelopers from "../../static/images/icon-target-developers.inline.svg";

import SectionLabel from "../components/SectionLabel";
import SectionTitle from "../components/SectionTitle";
import FixedBanner from "../components/FixedBanner";
import Form from "../components/Form";
import Quote from "../components/Quote";

export default () => {
  const heroRef = useRef();
  const ctaRef = useRef();
  const retailersRef = useRef();
  // const retailersRef = useRef();

  const [shake, setShake] = useState("");
  const [blasted, setBlasted] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShake("shake-little");
    }, 200);

    setTimeout(() => {
      setShake("done");

      setTimeout(() => {
        setBlasted("blasted");
      }, 50);
    }, 500);
  }, []);

  const { inView: heroInView } = useInView(heroRef, {
    threshold: 0, // Default is 0
    delay: 100,
  });

  const { inView: ctaInView } = useInView(ctaRef, {
    threshold: 0, // Default is 0
    delay: 100,
  });

  const { inView: retailersInView } = useInView(retailersRef, {
    threshold: 0.25, // Default is 0
    delay: 100,
  });

  const retailers = [
    {
      name: "Amazon.com",
      slug: "amazon",
      link: "https://www.amazon.com/dp/0063018292?tag=imprintweb-20",
    },
    {
      name: "Barnes & Noble",
      slug: "barnes-and-noble",
      link:
        "https://www.barnesandnoble.com/w/ask-your-developer-jeff-lawson/1136140709",
    },
    {
      name: "Indiebound.org",
      slug: "indiebound",
      link: "https://www.indiebound.org/book/9780063018297",
    },
    {
      name: "Google Play",
      slug: "google-play",
      link:
        "https://play.google.com/store/books/details/Jeff_Lawson_Ask_Your_Developer?id=lT3LDwAAQBAJ",
    },
    {
      name: "Apple Books",
      slug: "apple-books",
      link:
        "https://books.apple.com/us/book/ask-your-developer/id1495637653?ign-mpt=uo%3D4",
    },
    {
      name: "Hudson Booksellers",
      slug: "hudson",
      link: "https://www.hudsonbooksellers.com/book/9780063018297",
    },
    {
      name: "Kobo",
      slug: "rakuten-kobo",
      link: "https://www.kobo.com/us/en/audiobook/ask-your-developer",
    },
    {
      name: "Bookshop",
      slug: "bookshop",
      link:
        "https://bookshop.org/books/ask-your-developer-how-to-harness-the-power-of-software-developers-and-win-in-the-21st-century/9780063018297",
    },
  ];

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Helmet>
        {/* General tags */}
        <html lang="en" />
        <meta charSet="utf-8" />

        <title>Ask Your Developer by Jeff Lawson</title>
        <meta
          name="description"
          content="Jeff Lawson, software developer turned CEO of Twilio, shares a new approach for winning in the digital era. Unleash the creativity and productivity of the 25 million most important workers in the digital economy: software developers."
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link rel="icon" type="image/svg+xml" href="/images/favicon.svg" />

        <link rel="preload" href="/images/hero-blast.svg" as="image" />

        <meta name="viewport" content="width=device-width,initial-scale=1.0" />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Book",
            name:
              "Ask Your Developer: How to Harness the Power of Software Developers and Win in the 21st Century",
            author: {
              "@type": "Person",
              name: "Jeff Lawson",
            },
            url: "https://www.amazon.com/dp/0063018292?tag=imprintweb-20",
          })}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://www.askyourdeveloper.com" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Ask Your Developer by Jeff Lawson, co-founder and CEO of Twilio"
        />
        <meta
          property="og:description"
          content="Unleash the creativity and productivity of your important workers in the digital economy: software developers."
        />
        <meta
          property="og:image"
          content="https://www.askyourdeveloper.com/images/og-image.v1.jpg"
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@askyourdev" />
        <meta
          name="twitter:title"
          content="Ask Your Developer by Jeff Lawson, co-founder and CEO of Twilio"
        />
        <meta
          name="twitter:description"
          content="Unleash the creativity and productivity of your important workers in the digital economy: software developers."
        />
        <meta
          name="twitter:image"
          content="https://www.askyourdeveloper.com/images/og-image.v1.jpg"
        />

        <body className={`${showModal ? "overflow-hidden" : ""}`} />
      </Helmet>

      <div ref={heroRef} className="hero bg-ruby py-10">
        <div className={`blast ${blasted}`}>
          <div className="correct">
            <div
              className={`scale ${!!(shake && shake !== "done") && "up"} ${
                !!(shake === "done") && "down"
              }`}
            >
              <CodeWhite className={`${shake} shake-constant`} />
              <CodeRed />
            </div>
          </div>
        </div>

        <div className={`band shadow-lg text-center px-6 py-7 ${blasted}`}>
          <h1 className="text-white m-auto mb-4">
            <Title />
          </h1>
          <h2 className="text-banana m-auto">
            <Subtitle />
          </h2>
        </div>

        <div className="lead m-auto -mt-36 sm:-mt-40 md:-mt-44 sm:mr-auto sm:-mb-3 text-right relative z-20">
          <picture>
            <source
              media="(max-width: 576px)"
              srcSet="/images/hero-book-stack-sm.webp, /images/hero-book-stack-sm@2x.webp 2x"
              type="image/webp"
            />
            <source
              media="(min-width: 576px)"
              srcSet="/images/hero-book-stack.webp, /images/hero-book-stack@2x.webp 2x"
              type="image/webp"
            />
            <source
              media="(max-width: 576px)"
              srcSet="/images/hero-book-stack-sm.png, /images/hero-book-stack-sm@2x.png 2x"
              type="image/png"
            />
            <source
              media="(min-width: 576px)"
              srcSet="/images/hero-book-stack.png, /images/hero-book-stack@2x.png 2x"
              type="image/png"
            />
            <img
              src="/images/hero-book-stack.png"
              className="inline-block"
              // onError={(e) => { e.target.classList.add('error') }}
              alt="Ask Your Developer by Jeff Lawson"
            />
          </picture>
        </div>

        <p className="lead text-white m-auto md:text-lg mb-6 px-6 antialiased">
          <span className="font-semibold">Jeff Lawson</span>, software developer
          turned CEO of Twilio, creates a new playbook for unleashing the full
          potential of software developers in any organization, showing how to
          help management utilize this coveted and valuable workforce to enable
          growth, solve a wide range of business problems, and drive digital
          transformation.
        </p>

        <p className="lead text-white m-auto md:text-md mb-6 px-6 antialiased">
          All proceeds of Ask Your Developer are going to organizations who are
          working to ensure that developers and technologists of the future
          reflect the diverse world around us.{" "}
          <a href="/proceeds" className="hover:underline font-semibold">
            Learn about our four beneficiary organizations
          </a>
          .
        </p>

        <footer ref={ctaRef} className="items-center justify-between flex flex-col sm:flex-row-reverse cta-footer m-auto mb-4 mt-10 px-6">
          <picture className="flex-none mb-10 sm:mb-0">
            <source
              srcSet="/images/wall-street-journal-badge.webp, /images/wall-street-journal-badge@2x.webp 2x"
              type="image/webp"
            />
            <source
              srcSet="/images/wall-street-journal-badge.png, /images/wall-street-journal-badge@2x.png 2x"
              type="image/png"
            />
            <img
              width="230"
              height="70"
              src="/images/wall-street-journal-badge.png"
              // onError={(e) => { e.target.classList.add('error') }}
              alt="Wall Street Journal Bestseller"
            />
          </picture>
          <a
            href="#order"
            to="#order"
            onClick={(e) => {
              e.preventDefault();
              scroller.scrollTo("order", {
                offset: 0,
                smooth: false,
              });
            }}
            className="flex-none inline-block bg-white text-ruby text-md font-semibold py-4 px-12 shadow rounded"
          >
            Order your copy now
          </a>
        </footer>
      </div>

      <section className="overview">
        <SectionLabel color="rose" name="overview" width="185" height="87">
          Overview
        </SectionLabel>
        <SectionTitle color="ruby">
          How can you compete in the digital economy? <br />
          Ask your developer.
        </SectionTitle>

        <div className="highlight flex flex-col md:flex-row px-8 lg:px-0 m-auto my-20 lg:my-24 max-w-xl md:max-w-4xl">
          <div className="flex flex-row md:flex-col mb-3 md:w-2/4">
            <h4 className="text-rose text-xl sm:text-2xl md:text-3xl mr-5 mt-3 md:mt-0">
              The challenge companies face today
            </h4>
            <HighlightCompanies />
          </div>
          <div className="description md:w-2/4">
            <p className="text-lg md:text-xl text-denim">
              From banking and retail to insurance and finance, every industry
              is turning digital, and every company needs the best software to
              win the hearts and minds of customers.
            </p>
            <p className="text-lg md:text-xl text-denim">
              The landscape has shifted from the classic build vs. buy question,
              to one of <span class="italic">build vs. die</span>.
            </p>
            <p className="text-lg md:text-xl text-denim">
              Companies have to get this right to survive. But how do they make
              this transition?
            </p>
          </div>
        </div>

        <div className="highlight flex flex-col md:flex-row px-8 lg:px-0 m-auto mt-20 lg:mt-24 max-w-xl md:max-w-4xl">
          <div className="flex flex-row md:flex-col mb-3 md:w-2/4">
            <h4 className="text-rose text-xl sm:text-2xl md:text-3xl mr-5 mt-3 md:mt-0">
              Using the creativity of code to solve business problems
            </h4>
            <HighlightCreativity />
          </div>
          <div className="description md:w-2/4">
            <p className="text-lg md:text-xl text-denim">
              Developers are the creative workforce who can solve major business
              problems and create hit products for customers &mdash; not just
              grind through rote tasks. Most companies treat developers like
              digital factory workers without really understanding what software
              developers are able to contribute.
            </p>
            <p className="text-lg md:text-xl text-denim">
              From Google and Amazon, to one-person online software companies
              &mdash; companies that bring software developers in as partners
              are winning.
            </p>
          </div>
        </div>
      </section>

      <section className="mindset z-30">
        <div className="flex justify-center mb-4">
          <LazyLoadImage
            width="62"
            height="62"
            src={`/images/icon-mindset-title.svg`}
          />
        </div>
        <SectionTitle color="white">
          Adopt the <b>Ask Your Developer</b> mindset
        </SectionTitle>

        <div className="flex flex-col lg:flex-row items-start max-w-2xl lg:max-w-5xl m-auto px-6 lg:px-0">
          <div className="mindset lg:w-2/4 mt-16 md:mx-4 rounded-md shadow-lg">
            <h5 className="header bg-rose rounded-t-md text-white px-6 md:px-7 py-5 text-xl">
              <span>
                For <b>Companies</b>
              </span>
              <IconCompanies />
            </h5>
            <main className="px-6 md:px-7 py-8 pb-4">
              <p className="text-lg md:text-xl mb-6 text-denim">
                Take advantage of an underutilized asset that already exists in
                your company.
              </p>
              <ul>
                <li className="py-1 pb-2 mb-4 text-md md:text-lg text-denim">
                  Unleash the tremendous untapped creativity and brainpower
                  inside your software teams.
                </li>
                <li className="py-1 pb-2 mb-4 text-md md:text-lg text-denim">
                  Understand developers and how to motivate them.
                </li>
                <li className="py-1 pb-2 mb-4 text-md md:text-lg text-denim">
                  Invest in your developers' success.
                </li>
              </ul>
            </main>
          </div>
          <div className="mindset lg:w-2/4 mt-16 md:mx-4 rounded-md shadow-lg">
            <h5 className="header bg-rose rounded-t-md text-white px-6 md:px-7 py-5 text-xl">
              <span>
                For <b>Developers</b>
              </span>
              <IconDevelopers />
            </h5>
            <main className="px-6 md:px-7 py-8 pb-4">
              <p className="text-lg md:text-xl mb-6 text-denim">
                The <b>Ask Your Developer</b> mindset helps you show up as much
                more than just a code monkey.
              </p>
              <ul>
                <li className="py-1 pb-2 mb-4 text-md md:text-lg text-denim">
                  Live up to your potential as a full, talented, creative
                  professional.
                </li>
                <li className="py-1 pb-2 mb-4 text-md md:text-lg text-denim">
                  Be a valuable part of the strategic conversation.
                </li>
              </ul>
            </main>
          </div>
        </div>
      </section>

      <section className="about z-20">
        <SectionLabel color="rose" name="about" width="219" height="111">
          About the author
        </SectionLabel>

        <div className="flex flex-col lg:flex-row items-start max-w-xl lg:max-w-6xl m-auto px-6 lg:px-0">
          <div className="relative z-10 about lg:w-2/4 md:mx-4 lg:pr-2 lg:pl-6 xl:pr-12 xl:pl-8">
            <h4 className="text-2xl md:text-3xl text-denim mb-8 md:mb-12">
              Jeff Lawson
            </h4>
            <p className="text-lg xl:text-xl text-denim mb-8">
              Jeff Lawson is co-founder and CEO of Silicon Valley-based Twilio,
              one of the world’s fastest growing technology companies. A
              lifelong software developer, Jeff founded Twilio in 2008 with the
              belief that empowering developers was key to unlocking innovation
              within nearly every kind of business. Millions of software
              developers and hundreds of thousands of companies use Twilio’s
              platform to add communications to the websites and apps you use
              daily.
            </p>
            <p className="text-lg xl:text-xl text-denim mb-8">
              Jeff is a serial entrepreneur having been the founder of
              Versity.com and NineStar, and founding CTO of StubHub prior to
              Twilio, as well as one of the first product managers at Amazon Web
              Services.
            </p>
            <p className="text-lg xl:text-xl text-denim mb-20">
              He lives in San Francisco with his family and still makes time to
              hack on new software and hardware projects.
            </p>
          </div>
          <div className="headshot md:mx-4 lg:-mt-8 xl:-mt-4">
            <LazyLoadComponent>
              <picture className="shadow-lg z-20">
                <source
                  srcSet="/images/jeff-lawson.webp, /images/jeff-lawson@2x.webp 2x"
                  type="image/webp"
                />
                <source
                  srcSet="/images/jeff-lawson.jpg, /images/jeff-lawson@2x.jpg 2x"
                  type="image/jpeg"
                />
                <img
                  className="shadow-lg rounded-full relative z-20"
                  width="512"
                  height="512"
                  src="/images/jeff-lawson.jpg"
                  // onError={(e) => { e.target.classList.add('error') }}
                  alt="Jeff Lawson - Co-Founder & CEO of Twilio"
                />
              </picture>
            </LazyLoadComponent>
          </div>
        </div>
      </section>

      <Element id="order">
        <section ref={retailersRef} className="order z-10">
          <SectionLabel color="white" name="order">
            Order your copy
          </SectionLabel>
          <SectionTitle color="white">
            Available at these retailers
          </SectionTitle>

          <div className="flex flex-wrap m-auto max-w-6xl px-4 mt-8">
            {retailers.map((retailer) => {
              return (
                <div
                  key={retailer.slug}
                  className="w-1/2 md:w-1/3 lg:w-1/4 mb-4 px-2 h-32 md:h-36"
                >
                  <a
                    href={retailer.link}
                    data-retailer={retailer.slug}
                    className="retailer flex items-center justify-center bg-white h-full rounded-md px-6"
                  >
                    <LazyLoadImage
                      threshold="400"
                      src={`/images/logo-retailer-${retailer.slug}.svg`}
                      // onError={(e) => { e.target.classList.add('error') }}
                      alt={retailer.name}
                    />
                    <span>{retailer.name}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </section>
      </Element>

      <section className="quotes z-20 pb-4">
        <SectionLabel color="rose" name="quotes">
          Quotes
        </SectionLabel>
        <div className="flex flex-wrap m-auto max-w-7xl px-10 mt-8">
          <Quote
            layout="left"
            photo="marc-benioff"
            name="Marc Benioff"
            title="Chair and CEO of Salesforce"
          >
            Jeff Lawson’s <i>Ask Your Developer</i> is the ultimate guide for
            creating a software developer culture that unlocks the passion,
            creativity, and empathy needed to build amazing products and succeed
            in the digital economy.
          </Quote>
          <Quote
            layout="right"
            photo="padmasree-warrior"
            name="Padmasree Warrior"
            title="Founder, CEO, and President of Fable Group Inc."
          >
            Jeff shows business leaders at every level how to unlock growth and
            innovation while empowering their talent. Crisp and easy to read.
          </Quote>
          <Quote
            layout="left"
            photo="anil-dash"
            name="Anil Dash"
            title="CEO of Glitch, Entrepreneur, and Writer"
          >
            Thank you, Jeff, for teaching both developers and business leaders
            how to speak the same language &mdash; this is going to smash so
            many misconceptions that get in the way of innovation.
          </Quote>
          <Quote
            layout="right"
            photo="werner-vogels"
            name="Werner Vogels"
            title="CTO of Amazon"
          >
            Engineering is one of the most creative jobs in the world. Jeff’s{" "}
            <i>Ask Your Developer</i> philosophy is spot on &mdash; unleashing
            the creativity of developers is the key to innovation, no matter
            what industry you are in.
          </Quote>
          <Quote
            layout="left"
            photo="adam-bryant"
            name="Adam Bryant"
            title="Co-author of The CEO Test"
          >
            I’ve been studying culture in companies for a long time, and I’ve
            talked to hundreds of CEOs. What Jeff and the team have done is one
            of the most complete and thoughtful approaches to culture I’ve come
            across yet. <i>Ask Your Developer</i> is a must-read for any leader
            who is looking for a playbook from one of the most intentional CEOs
            I’ve interviewed.
          </Quote>
        </div>
      </section>

      <section className="press z-20">
        <SectionLabel color="rose" name="press">
          Press
        </SectionLabel>
        <SectionTitle color="black">
          For inquiries, please contact us at{" "}
          <a href="mailto:press@askyourdeveloper.com">
            press@askyourdeveloper.com
          </a>
        </SectionTitle>
      </section>

      <FixedBanner show={!(heroInView || ctaInView || retailersInView)} />

      <div className="bg-ruby">
        <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            <div className="px-5 py-2">
              <a
                href="/proceeds"
                className="text-base text-white leading-6 text-gray-500 hover:text-gray-900"
              >
                Proceeds
              </a>
            </div>
            <div className="px-5 py-2">
              <a
                href="https://www.twilio.com/legal/tos"
                className="text-base text-white leading-6 text-gray-500 hover:text-gray-900"
              >
                Legal
              </a>
            </div>
            <div className="px-5 py-2">
              <a
                href="https://www.twilio.com"
                className="text-base text-white leading-6 text-gray-500 hover:text-gray-900"
              >
                Twilio.com
              </a>
            </div>
          </nav>
          <div className="mt-8">
            <p className="text-center text-white text-base leading-6 text-gray-400">
              &copy; 2020 Twilio Inc. All rights reserved.
            </p>
          </div>
        </div>
      </div>

      {showModal && <Form hideModal={hideModal} retailers={retailers} />}
    </div>
  );
};
