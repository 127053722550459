import React from "react";
import { Formik } from "formik";
import axios from "axios";
import US from "us";

export default function Form(props) {
  const states = US.STATES;

  return (
    <div
      x-data="{ open: true }"
      x-show="open"
      className="fixed z-50 top-0 bottom-0 inset-x-0 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center overflow-auto"
    >
      <div x-show="open" className="fixed inset-0 transition-opacity">
        <div
          className="absolute inset-0 bg-rose opacity-75"
          onClick={props.hideModal}
        ></div>
      </div>
      <div
        className="bg-white sm:rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <Formik
          initialValues={{
            name: "",
            email: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            retailer: "",
          }}
          validate={(values) => {
            const errors = {};

            // Check for empty values
            if (!values.name) {
              errors.name = "Please provide your name.";
            }

            if (!values.email) {
              errors.email = "Please enter your email address.";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Please enter a valid email address.";
            }

            if (!values.address) {
              errors.address = "Please enter your mailing address.";
            }

            if (!values.city && !values.state && !values.zipCode) {
              errors.zone = "Please enter your city, state, and ZIP code.";
              errors.city = true;
              errors.state = true;
              errors.zipCode = true;
            } else if (!values.city && !values.state) {
              errors.zone = "Please enter your city and state.";
              errors.city = true;
              errors.state = true;
            } else if (!values.state && !values.zipCode) {
              errors.zone = "Please enter your state and ZIP code.";
              errors.state = true;
              errors.zipCode = true;
            } else if (!values.city && !values.zipCode) {
              errors.zone = "Please enter your city and ZIP code.";
              errors.city = true;
              errors.zipCode = true;
            } else if (!values.city) {
              errors.zone = "Please enter your city.";
              errors.city = true;
            } else if (!values.state) {
              errors.zone = "Please enter your state.";
              errors.state = true;
            } else if (!values.zipCode) {
              errors.zone = "Please enter your ZIP code.";
              errors.zipCode = true;
            } else if (
              values.zipCode &&
              !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(values.zipCode)
            ) {
              errors.zone = "Please enter a valid US ZIP code.";
              errors.zipCode = true;
            }

            if (!values.retailer) {
              errors.retailer = "Please select a retailer name.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await axios({
              url: "/submit",
              method: "POST",
              headers: {
                "X-Test": "hoorah",
              },
              data: values,
            });

            props.hideModal();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6"
              onSubmit={handleSubmit}
              method="post"
            >
              <div className="sm:col-span-6">
                <h4 className="text-xl lg:text-2xl font-semibold">
                  Claim your pre-order bonuses
                </h4>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-5 mb-1"
                >
                  Name
                </label>
                <div className="mt-1 rounded-md shadow-sm relative">
                  <input
                    id="name"
                    autoComplete="name"
                    className={`form-input block w-full transition duration-150 ease-in-out sm:text-md sm:leading-5 disabled:opacity-50 ${
                      errors.name && touched.name ? "border-ruby" : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-rose"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                {errors.name && touched.name && (
                  <p
                    className="mt-1 text-sm font-medium text-ruby"
                    id="email-error"
                  >
                    {errors.name}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 mb-1"
                >
                  Email Address
                </label>
                <div className="mt-1 rounded-md shadow-sm relative">
                  <input
                    id="email"
                    autoComplete="email"
                    className={`form-input block w-full transition duration-150 ease-in-out sm:text-md sm:leading-5 disabled:opacity-50 ${
                      errors.email && touched.email
                        ? "border-ruby focus:border-ruby focus:shadow-outline-red"
                        : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-rose"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                {errors.email && touched.email && (
                  <p
                    className="mt-1 text-sm font-medium text-ruby"
                    id="email-error"
                  >
                    {errors.email}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium leading-5 mb-1"
                >
                  Mailing Address
                </label>
                <div className="mt-1 rounded-md shadow-sm relative">
                  <input
                    id="address"
                    autoComplete="street-address"
                    className={`form-input block w-full transition duration-150 ease-in-out sm:text-md sm:leading-5 disabled:opacity-50 ${
                      errors.address && touched.address
                        ? "border-ruby focus:border-ruby focus:shadow-outline-red"
                        : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.address}
                  />
                  {errors.address && touched.address && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-rose"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                {errors.address && touched.address && (
                  <p
                    className="mt-1 text-sm font-medium text-ruby"
                    id="email-error"
                  >
                    {errors.address}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-12">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-5 mb-1"
                  >
                    City
                  </label>
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <input
                      id="city"
                      autoComplete="address-level2"
                      className={`form-input block w-full transition duration-150 ease-in-out sm:text-md sm:leading-5 disabled:opacity-50 ${
                        errors.city && touched.city
                          ? "border-ruby focus:border-ruby focus:shadow-outline-red"
                          : ""
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.city}
                    />
                    {errors.city && touched.city && (
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <svg
                          className="h-5 w-5 text-rose"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="rounded-md shadow-sm">
                    <label
                      htmlFor="state"
                      className="block text-sm leading-5 font-medium mb-1"
                    >
                      State
                    </label>
                    <select
                      id="state"
                      autoComplete="address-level1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.state}
                      className={`mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 disabled:opacity-50 ${
                        errors.state && touched.state
                          ? "border-ruby focus:border-ruby focus:shadow-outline-red"
                          : ""
                      }`}
                    >
                      <option></option>
                      {states.map((state, index) => {
                        return <option key={index}>{state.abbr}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="zipCode"
                    className="block text-sm font-medium leading-5 mb-1"
                  >
                    ZIP Code
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="zipCode"
                      autoComplete="postal-code"
                      className={`form-input block w-full transition duration-150 ease-in-out sm:text-md sm:leading-5 disabled:opacity-50 ${
                        errors.zipCode && touched.zipCode
                          ? "border-ruby focus:border-ruby focus:shadow-outline-red"
                          : ""
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.zipCode}
                    />
                  </div>
                </div>
              </div>

              {errors.zone &&
                (touched.city || touched.state || touched.zipCode) && (
                  <div className="sm:col-span-6 -mt-6">
                    <p
                      className="mt-1 text-sm font-medium text-ruby"
                      id="email-error"
                    >
                      {errors.zone}
                    </p>
                  </div>
                )}

              <div className="sm:col-span-6">
                <label
                  htmlFor="retailer"
                  className="block text-sm font-medium leading-5 mb-1"
                >
                  Where did you purchase?
                </label>
                <div className="mt-1 rounded-md shadow-sm relative">
                  <select
                    id="retailer"
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.retailer}
                    className={`mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 disabled:opacity-50 ${
                      errors.retailer && touched.retailer
                        ? "border-ruby focus:border-ruby focus:shadow-outline-red"
                        : ""
                    }`}
                  >
                    <option></option>
                    {props.retailers.map((retailer, index) => {
                      return <option key={index}>{retailer.name}</option>;
                    })}
                    <option disabled>-----</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.retailer && touched.retailer && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-rose"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                {errors.retailer && touched.retailer && (
                  <p
                    className="mt-1 text-sm font-medium text-ruby"
                    id="email-error"
                  >
                    {errors.retailer}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6 mt-2">
                <div className="rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="block w-full items-center px-4 py-4 border border-transparent text-lg leading-6 font-medium rounded-md text-white bg-ruby hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 disabled:opacity-75 disabled:cursor-not-allowed"
                    disabled={isSubmitting}
                  >
                    Submit
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="inline-block h-5 w-5 -mr-1 ml-2 -mt-1"
                    >
                      <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="sm:col-span-6 mt-2 mb-6 lg:mb-0">
                <div className="text-sm">
                  We are asking for the information above in order to contact
                  you about your pre-order and ship your bonus kit. All data is
                  handled according to the{" "}
                  <a
                    className="text-ruby hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.twilio.com/legal/privacy"
                  >
                    Twilio privacy policy
                  </a>
                  .
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

// export default Form;
