import React from "react";
import Transition from "../components/Transition";
import { scroller } from "react-scroll";

export default function SectionLabel(props) {
  return (
    <Transition
      show={props.show}
      enter="transform ease-out duration-300 transition"
      enterFrom="-translate-y-5 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transform transition ease-in duration-150"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="opacity-0 -translate-y-5"
    >
      <div className="opacity-0 fixed top-5 inset-x-0 pb-2 sm:pb-5 z-50">
        <div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-ruby shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-ruby-800">
                  <svg viewBox="0 0 24 24" width="24" height="24" fill="white">
                    <path
                      className="heroicon-ui"
                      d="M20.59 12l-3.3-3.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l3.3-3.3zM3.4 12l3.3 3.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 1.4L3.4 12zm7.56 8.24a1 1 0 0 1-1.94-.48l4-16a1 1 0 1 1 1.94.48l-4 16z"
                    />
                  </svg>
                </span>
                <p className="ml-2 font-medium text-white truncate">
                  <span className="sm:hidden">Available January 12, 2021</span>
                  <span className="hidden sm:inline">
                    <b className="inline-block mr-2">Ask Your Developer</b>{" "}
                    Available today!
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <div className="rounded-md shadow-sm">
                  <a
                    href="#order"
                    to="#order"
                    onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo("order", {
                        offset: 0,
                        smooth: false,
                      });
                    }}
                    className="flex items-center justify-center px-4 py-2 text-md leading-5 font-bold rounded-md text-ruby bg-white hover:text-ruby focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                  >
                    Order your copy now
                  </a>
                </div>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-ruby focus:outline-none focus:bg-ruby transition ease-in-out duration-150"
                  aria-label="Dismiss"
                >
                  <svg
                    className="h-6 w-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
